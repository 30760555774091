<template>
  <div class="app-home">
    <div class="aside">
      <div class="top"></div>
      <div class="title">
        <img :src="require('@/assets/images/member/dot01.jpg')" alt="">
        <span>个人资料</span>
      </div>
      <div class="wel">
        <div class="wel-txt">欢迎您</div>
        <div class="code">{{ userCode }}</div>
      </div>
      <div class="menu">
        <div class="item">
          <img class="icon" :src="require('@/assets/images/member/icon_user.png')" >
          <div class="title">
            <div>{{ username }}</div>
          </div>
        </div>
        <div class="item">
          <img class="icon" :src="require('@/assets/images/member/icon_note.png')" >
          <div class="title">
            <div>账户</div>
            <div v-show="!loading" class="tip">{{ ((home.balance)*1).toFixed(2) }}</div>
          </div>
        </div>
        <div v-show="!isAgent" class="item">
          <img class="icon" :src="require('@/assets/images/member/icon_note.png')" >
          <div class="title">
            <div>活跃度</div>
            <div v-show="!loading" class="tip">{{ ((home.activity)*1).toFixed(2) }}</div>
          </div>
        </div>
        <div class="item">
          <img class="icon" :src="require('@/assets/images/member/icon_note.png')" >
          <div class="title">
            <div>A积分</div>
            <div v-show="!loading" class="tip">{{ ((home.points)*1).toFixed(2) }}</div>
          </div>
        </div>
        <div class="item">
          <img class="icon" :src="require('@/assets/images/member/icon_edit.png')" >
          <div class="title">
            <div>上次登录</div>
            <div class="tip">{{ home.lastLoginTime }}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="content-wrapper">
      <div class="top"></div>
      <div class="notice">
        <div v-show="isAgent" class="box-card">
          <el-form :inline="true" :model="listQuery" label-width="80px" size="mini" class="form">
            <el-form-item label="报单时间">
              <!-- 时间起始 -->
              <el-date-picker
                  v-model="listQuery.from"
                  type="date"
                  :picker-options="this.startDate"
                  style="width: 130px;"
                  value-format="yyyy-MM-dd"
                  placeholder="开始时间"
              />&nbsp;&nbsp; - &nbsp;&nbsp;
              <!-- 时间结束 -->
              <el-date-picker
                  v-model="listQuery.to"
                  :picker-options="this.end_Date"
                  type="date"
                  style="width: 130px;"
                  value-format="yyyy-MM-dd"
                  placeholder="结束时间"
              />
            </el-form-item>
            <el-form-item label="顾客编号:">
              <el-input v-model.trim="listQuery.memberNo" style="width:160px;" />
            </el-form-item>
            <el-form-item label="顾客姓名:">
              <el-input v-model.trim="listQuery.memberName" style="width:160px;" />
            </el-form-item>
            <el-form-item label="出库时间">
              <!-- 时间起始 -->
              <el-date-picker
                  v-model="listQuery.fromOut"
                  type="date"
                  :picker-options="this.startDate"
                  style="width: 130px;"
                  value-format="yyyy-MM-dd"
                  placeholder="开始时间"
              />&nbsp;&nbsp; - &nbsp;&nbsp;
              <!-- 时间结束 -->
              <el-date-picker
                  v-model="listQuery.toOut"
                  :picker-options="this.end_Date"
                  type="date"
                  style="width: 130px;"
                  value-format="yyyy-MM-dd"
                  placeholder="结束时间"
              />
            </el-form-item>
            <el-form-item label="单据编号:">
              <el-input v-model.trim="listQuery.orderNo" style="width:160px;" />
            </el-form-item>
            <!--          <el-form-item label="订单状态:">
                        <el-select v-model="listQuery.status" clearable style="width:160px;">
                          <el-option v-for="item in sendStatusList" :key="item.code" :label="$lt(initDict.sendStatus, item.code)" :value="item.code" />
                        </el-select>
                      </el-form-item>-->
            <el-form-item style="margin-left:5px;">
              <el-button type="primary" plain icon="el-icon-search" @click="handleFilter">查询</el-button>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" icon="el-icon-plus" @click="handleAdd">新增出货单</el-button>
            </el-form-item>
          </el-form>
          <el-table ref="outTable" :data="list" v-loading="loading" style="width: 100%" size="mini" border fit stripe class="t-out">
            <el-table-column type="selection" :selectable="checkSelectable" width="40" align="center" />
            <!--        <el-table-column label="操作" width="40" align="center">-->
            <!--          <template slot-scope="{row}">-->
            <!--            <div class="opts">-->
            <!--              <el-tooltip class="item" effect="dark" content="详情" placement="top">-->
            <!--                <i class="button el-icon-search" @click="handleView(row)"/>-->
            <!--              </el-tooltip>-->
            <!--            </div>-->
            <!--          </template>-->
            <!--        </el-table-column>-->
            <el-table-column label="订单编号" prop="orderNo" width="140" align="center" />
            <el-table-column label="订单类型" width="80" align="center">
              <template slot-scope="{row}">
                <span>{{ $lt(initDict.orderType, row.orderType) }}</span>
              </template>
            </el-table-column>
            <el-table-column label="顾客编号" prop="memberNo" align="center" />
            <el-table-column label="姓名" prop="memberName" align="center" />
            <el-table-column label="订单已过（天）" class-name="fot-col" width="100" align="center" >
              <template slot-scope="{row}">
                <span v-if="row.titime>30" class="fot-col">{{row.titime}}</span>
                <span v-else>{{row.titime}}</span>
              </template>
            </el-table-column>
            <el-table-column label="金额" prop="totalAmount" width="80" align="center" />
            <el-table-column label="消费指数" prop="totalFv" width="80" align="center" />
            <el-table-column label="换货积分" prop="totalpointa0" width="80" align="center" />
            <el-table-column label="状态" width="60" align="center">
              <template slot-scope="{row}">
                <span>{{ $lt(initDict.sendStatus, row.sendFlag) }}</span>
              </template>
            </el-table-column>
            <el-table-column label="报单时间" prop="createdTime" width="130" align="center" />
            <el-table-column label="顾客姓名" prop="memberName"  align="center" />
            <el-table-column label="出库单号" prop="outNo" align="center" />
            <el-table-column label="急提时间" prop="urgentTime"  align="center" />
            <!--        <el-table-column type="expand">-->
            <!--          <template slot-scope="{row}">-->
            <!--            <el-form label-position="right" label-width="80px" inline class="out-table-expand">-->
            <!--              <el-form-item label="顾客姓名:">-->
            <!--                <span>{{ row.memberName }}</span>-->
            <!--              </el-form-item>-->
            <!--              <el-form-item label="出库单号:">-->
            <!--                <span>{{ row.outNo }}</span>-->
            <!--              </el-form-item>-->
            <!--              <el-form-item label="急提时间:">-->
            <!--                <span>{{ row.urgentTime }}</span>-->
            <!--              </el-form-item>-->
            <!--            </el-form>-->
            <!--          </template>-->
            <!--        </el-table-column>-->
          </el-table>
          <pagination :total="total" :page.sync="listQuery.page" :limit.sync="listQuery.limit" @pagination="getList" />
        </div>

        <el-card v-show="!isAgent" class="box-card">
          <div slot="header" class="clearfix">
            <span>公告</span>
            <el-button style="float: right; padding: 3px 0" type="text">更多</el-button>
          </div>
          <el-table :key="tableKey" :data="notices" :border="false" :show-header="false">
            <el-table-column prop="title" />
          </el-table>
        </el-card>

      </div>
    </div>

    <el-dialog
        center
        width="50%"
        title="顾客须知"
        :visible.sync="dialogVisible">
      <div>
        <div>
          <h3>一、顾客定义</h3>
          1. 顾客：按照公司要求完整、详细、真实填写相关信息进行注册、并经公司审核同意的自然人，包括普通顾客和优惠顾客。
          <br/>
          2. 普通顾客：累计消费公司产品，消费指数不足2100的顾客群体。
          <br/>
          3. 优惠顾客：累计消费公司产品，消费指数超过2100（含）的顾客群体。
          <br/>
          <br/>
          <h3>二、顾客资格</h3>
          1. 申请条件
          <br/>
          （1）年满18周岁的任何人士，具有民事行为能力且自愿申请。
          <br/>
          （2）公司只接受自然人申请。
          <br/>
          2. 申请手续
          <br/>
          （1）免费注册，即注册时公司不收取任何费用。
          <br/>
          （2）登录公司网站，在线办理申请手续，按照公司要求完整、详细、真实填写相关注册信息，经公司审核同意后顾客资格生效。
          <br/>
          （3）任何人不得重复申请公司的顾客资格。
          <br/>
          （4）公司有权自行决定是否接受任何顾客资格申请。
          <br/>
          3. 顾客资格的有效期
          <br/>
          （1）顾客资格有效期为一年，具体起始时间以在线提交注册申请之日起计算。
          <br/>
          （2）在顾客资格有效期内，可享受顾客的相关权益。
          <br/>
          4. 顾客资格的免费续约
          <br/>
          顾客资格有效期内，遵守公司制定的行为规范及其他相关制度、规定，无重大违法/违规行为，即可免费续约。
          <br/>
          5. 顾客资格的终止
          <br/>
          顾客资格终止后，则不再享有顾客的相关权益：
          <br/>
          （1）顾客本人以书面形式向公司申请终止。
          <br/>
          （2）顾客本人没有达到资格自动延续的条件。
          <br/>
          （3）根据公司相关业务规章制度，公司终止顾客资格。
          <br/>
          <br/>
          <br/>
          <h3>三、顾客权益</h3>
          1. 普通顾客权益：
          <br/>
          （1）根据自身需求，自主决定、选择、购买、使用公司产品、接受公司服务。
          <br/>
          （2）享有购买公司产品满一定金额后的免费配送服务。
          <br/>
          （3）享有使用公司相关电子资讯渠道获取资讯信息的服务。
          <br/>
          2. 优惠顾客权益：
          <br/>
          （1）根据自身需求，自主决定、选择购买、使用公司产品、接受公司服务。
          <br/>
          （2）享有购买公司产品满一定金额后的免费配送服务。
          <br/>
          （3）享有使用公司相关电子资讯渠道获取资讯信息的服务。
          <br/>
          （4）享受优惠价格、折扣购买公司产品。
          <br/>
          （5）参与公司促销活动、优惠计划。
          <br/>
          （6）获取并使用电子消费券兑换公司产品。
          <br/>
          <br/>
          <br/>
          <h3>四、其他</h3>
          1. 公司制定的市场支持方案、业务促销政策等相关规则内容若与本须知冲突，以公司制定的市场支持方案、业务促销政策等相关规则内容为准。
          <br/>
          2. 本须知自公布之日起生效施行。
          <br/>
          3. 公司在法律范围内，可根据实际需要修订本须知的有关条款，相关修订内容将通过公司官方渠道公布并实施。
          <br/>
          4. 本《顾客须知》的最终解释权归天津和治友德制药有限公司所有。
          <br/>
          以上内容如有与国家现行法律、法规相冲突的，以国家现行法律、法规为准。
          <br/>
          <br/>
          <br/>
        </div>
        <div style="text-align: center">
          <el-button
              type="primary"
              icon="el-icon-check"
              :loading="loading"
              @click="afterRead">已仔细阅读并同意
          </el-button>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import Pagination from '@/components/Pagination'
import { mapGetters } from 'vuex'
import { num2string } from '@/utils/num2string'
import { fetchHomepage } from '@/api/home'
import {getMemberTime, saveMemberTime} from "@/api/auth";
import { fetlistHome } from '@/api/order/order-out'
export default {
  name: 'Home',
  components: { Pagination },
  data() {
    return {
      dialogVisible: false,
      loading: false,
      home: {
        balance: '',
        activity: '',
        points: '',
        lastLoginTime: ''
      },
      list: [],
      total: 0,
      listQuery: {
        page: 1,
        limit: 10,
        memberNo: undefined,
        memberName: undefined,
        orderNo: undefined,
        status: undefined,
        from: "2022-10-12",
        to: undefined,
        fromOut: undefined,
        toOut: undefined
      },
      startDate:{
        disabledDate: time => {
          const start = Date.parse("2022-10-11")
          return time.getTime() < start
        }
      },
      initDict: {
        sendStatus: 'status.po.stockout',
        orderType: 'po.allordertype.cn'
      },
      tableKey: 1,
      notices: []
    }
  },
  computed: {
    ...mapGetters([
      'userCode',
      'username',
      'isAgent'
    ]),
    sendStatusList() {
      return this.$ll(this.initDict.sendStatus)
    }
  },
  mounted() {
    this.getData()
    this.loading = true
    if (this.isAgent){
      this.getList()
    }else{
      getMemberTime().then(res => {
        if (Number(res.data)) {
          this.dialogVisible = true
        }
      }).catch(err => {
        console.warn(err)
        this.loading = false
      })
    }
  },
  methods: {
    getList() {
      this.loading = true
      fetlistHome(this.listQuery).then(res => {
        this.list = res.data.records
        this.total = res.data.total ? +res.data.total : 0
        this.loading = false
      }).catch(() => {
        this.loading = false
      })
    },
    handleFilter() {
      this.listQuery.page = 1
      this.getList()
    },
    checkSelectable(row) {
      return row.sendFlag === 0
    },
    handleAdd() {
      const selection = this.$refs['outTable'].selection
      const orders = selection.map(ele => ele.id)
      if (!orders || !orders.length) {
        this.$message.error('至少需要选择一个订单')
      } else {
        this.$router.push(`/order/out/detail?id=${orders}`)
      }
    },
    getData() {
      this.loading = true
      fetchHomepage().then(res => {
        this.home = res.data
        this.loading = false
      }).catch(err => {
        console.warn(err)
        this.loading = false
      })
    },
    parseNumber(val) {
      return num2string(val)
    },
    afterRead() {
      this.loading = true
      saveMemberTime({
        userCode: this.userCode,
      }).then((res) => {
        if (res.success) {
          this.dialogVisible = false
        }
        this.loading = false
      }).catch(err => {
        console.warn(err)
        this.$message.error('同意《顾客须知》失败!')
        this.loading = false
      })
    },
  }
}
</script>

<style lang="scss">
.form{
  text-align: left;
  border-bottom: 1px #EBEEF5 solid;
}
.fot-col {
  color: red;
}
</style>
